<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6">
        <span>Your Organizations
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="darkGrey"
                      size="18"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    Manage all the organizations to which you belong as either a staff member, a board member, or a notification recipient
                  </span>
                </v-tooltip>
                </span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="closeModal"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>
        <v-tabs
          color="secondary"
          v-model="activeTab"
          fixed-tabs
          icons-and-text
        >
        <v-tabs-slider></v-tabs-slider>
          <v-tab class="mr-8 d-flex justify-center" name="staffTab">
            Staff
            <v-icon>mdi-account-outline</v-icon>
          </v-tab>
          <v-tab class="mr-8 d-flex justify-center" name="boardMemberTab">
            Board Member
            <v-icon>mdi-gavel</v-icon>
          </v-tab>
          <v-tab class="mr-8 d-flex justify-center" name="notificationsTab">
            Notifications
            <v-icon>mdi-inbox-arrow-down</v-icon>
          </v-tab>

          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col class="d-flex flex-row">
                      <span class="d-flex align-center justify-center">You have staff user access to the organizations listed below.</span>
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              large
                              color="accentYellow"
                              v-on="on"
                              href="/user-organizations"
                            >
                              <v-icon dark>
                                mdi-bank-plus
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Request to join another organization
                          </span>
                        </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-list
                  style="background-color: transparent;"
                  light>
                    <v-list-item
                    class="my-1 blue-grey lighten-5"
                    style="border: solid 2px black;border-radius: 10px;"
                    v-for="organization in userOrganizations"
                    v-bind:key="organization.id"
                    >
                        <v-list-item-avatar>
                          <v-img
                            :src="pathToClientAvatars + organization.logo"
                          >
                          </v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                        <v-list-item-title>
                          {{organization.name}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{organization.city}}, {{organization.state}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-speed-dial
                          direction="left"
                          :open-on-hover=false
                          transition="fab-transition"
                        >
                          <template v-slot:activator>
                            <v-tooltip
                              top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  color="white"
                                  fab
                                  x-small
                                  v-model="fab"
                                  v-on="on"
                                >
                                  <v-icon v-if="fab">
                                    mdi-back
                                  </v-icon>
                                  <v-icon
                                    v-else color="accentRed"
                                    >mdi-bank-off-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>
                                Leave the {{organization.name}} organization account
                              </span>
                            </v-tooltip>
                          </template>
                          <v-btn
                            fab
                            dark
                            x-small
                            color="grey"
                          >
                            no
                          </v-btn>
                          <v-btn
                            fab
                            dark
                            x-small
                            color="green"
                          >
                            yes
                          </v-btn>
                        </v-speed-dial>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-container>
                <v-container>
                  <v-row>
                    <v-col class="d-flex flex-row">
                      <span class="d-flex align-center justify-center">You have pending join requests to the organizations listed below.</span>
                    </v-col>
                  </v-row>
                  <v-list
                  style="background-color: transparent;"
                  light>
                    <v-list-item
                    class="my-1 blue-grey lighten-5"
                    style="border-radius: 10px;"
                    v-for="organization in userOrganizationsPending"
                    v-bind:key="organization.id"
                    >
                        <v-list-item-avatar>
                          <v-img
                            :src="pathToClientAvatars + organization.logo"
                          >
                          </v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                        <v-list-item-title>
                          {{organization.name}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{organization.city}}, {{organization.state}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                                  v-if="organization.need_auth"
                                  color="orange"
                                >
                                  mdi-alert
                                </v-icon>
                          </template>
                          <span>
                            The administrator for the {{organization.name}} organization account has requested additional information from you before the request can be approved.<br>
                            Please check your email for a message regarding the request, and contact the organization if you have questions.
                          </span>
                        </v-tooltip>
                      </v-list-item-icon>
                      <v-list-item-action>
                        <v-speed-dial
                          direction="left"
                          :open-on-hover=false
                          transition="fab-transition"
                        >
                          <template v-slot:activator>
                            <v-tooltip
                              top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  color="accentRed"
                                  fab
                                  x-small
                                  v-model="fab"
                                  v-on="on"
                                >
                                  <v-icon v-if="fab">
                                    mdi-back
                                  </v-icon>
                                  <v-icon
                                    v-else color="white"
                                    >mdi-close</v-icon>
                                </v-btn>
                              </template>
                              <span>
                                Cancel your request to join the {{organization.name}} organization account
                              </span>
                            </v-tooltip>
                          </template>
                          <v-btn
                            fab
                            dark
                            x-small
                            color="grey"
                          >
                            no
                          </v-btn>
                          <v-btn
                            fab
                            dark
                            x-small
                            color="green"
                            @click="cancelRequest(organization.id)"
                          >
                            yes
                          </v-btn>
                        </v-speed-dial>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat>
              <v-card-text>
                Board Members
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col class="d-flex flex-row mb-4">
                      <span class="d-flex align-center justify-center">You have subscribed to receive notifications from at least one board the organizations listed below. Expand the organization to view the boards for which you are receiving notification.</span>
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              large
                              color="accentBlue"
                              v-on="on"
                              href="/user-notifications"
                            >
                              <v-icon dark>
                                mdi-cog
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Manage your notifications
                          </span>
                        </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-expansion-panels
                  style="background-color: transparent;"
                  light>
                    <v-expansion-panel
                    class="my-1 lighten-5"
                    style="border: solid 2px black;border-radius: 10px;"
                    v-for="(notificationClient, clientId, index) in userNotifications"
                    v-bind:key="index"
                    >

                        <v-expansion-panel-header class="pa-2">
                          <v-row>
                            <v-col
                              cols=2>
                              <v-img
                                contain
                                height="40"
                                :src="pathToClientAvatars + notificationClient.logo"
                              >
                              </v-img>
                            </v-col>
                            <v-col>
                              <span class="font-weight-bold">{{notificationClient.name}}</span><br>
                              {{notificationClient.city}}, {{notificationClient.state}}
                            </v-col>
                            <v-spacer></v-spacer>
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list
                          style="background-color: transparent;"
                          light>
                            <v-list-item
                            v-for="(notificationBoard, boardId, index) in notificationClient.boards"
                            v-bind:key="index"
                            >
                                <v-list-item-content>
                                <v-list-item-title>
                                  {{notificationBoard.name}}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-content>

                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-btn depressed width="130" @click="closeModal" class="mr-2">Close</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NotificationPublicSignups from '@/models/NotificationPublicSignups'

export default Vue.extend({
  name: 'MyOrganizations',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    activeTab: 'staffTab',
    form: {
      status: 'inactive'
    },
    activeOrganizations: {},
    userOrganizationsData: [],
    userOrganizationsDataPending: [],
    userOrganizationsDataPendingAuth: [],
    userNotificationsData: [],
    clientBoards: [],
    pathToClientAvatars: process.env.VUE_APP_IMAGES_S3_BUCKET + '/' + process.env.VUE_APP_IMAGES_S3_FOLDER_ORGANIZATION_AVATARS + '/',
    fab: false,
    saving: false
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    activeOrganizationsIndexed () {
      const organization = {}
      this.activeOrganizations.forEach(element => {
        organization[element.id] = element
      })

      return organization
    },
    userOrganizations () {
      const organization = []
      this.userOrganizationsData.forEach(element => {
        organization.push(this.activeOrganizationsIndexed[element.clientID])
      })

      return organization
    },
    userOrganizationsPending () {
      const organization = []
      this.userOrganizationsDataPending.forEach(element => {
        this.activeOrganizationsIndexed[element.client_id].need_auth = false
        organization.push(this.activeOrganizationsIndexed[element.client_id])
      })

      this.userOrganizationsDataPendingAuth.forEach(element => {
        this.activeOrganizationsIndexed[element.client_id].need_auth = true
        organization.push(this.activeOrganizationsIndexed[element.client_id])
      })

      return organization
    },
    userNotifications () {
      const notifications = {}
      this.userNotificationsData.forEach(element => {
        if (element.status === 'active') {
          if (!notifications[element.board.client_id]) {
            notifications[element.board.client_id] = {}
          }
          notifications[element.board.client_id] = this.activeOrganizationsIndexed[element.board.client_id]

          if (!notifications[element.board.client_id].boards) {
            notifications[element.board.client_id].boards = {}
          }

          notifications[element.board.client_id].boards[element.board.id] = element.board
        }
      })

      return notifications
    }
  },
  methods: {
    getAllOrganizations () {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/clients/active-organizations')
        .then((response) => {
          this.activeOrganizations = response.data.all
          this.userOrganizationsData = response.data.userOrganizations.member
          this.userOrganizationsDataPending = response.data.userOrganizations.pending
          this.userOrganizationsDataPendingAuth = response.data.userOrganizations.pendingAuth
          this.getUserNotificationSignups()
        }).catch(function (error) {
          console.log(error)
        })
    },
    closeModal () {
      this.activeTab = 'staffTab'
      this.show = false
    },
    getUserNotificationSignups () {
      NotificationPublicSignups
        .custom('notification-public-signups/user')
        .params({
          board: true
        })
        .select({
          notification_public_signups: ['board_id', 'status']
        })
        .get()
        .then(response => {
          this.userNotificationsData = response
        }).catch(function (error) {
          console.log(error)
        })
    },
    getClientBoards (clientId) {
      NotificationPublicSignups
        .params({
          functionName: 'clientBoards',
          columns: ['name', 'id'],
          client_id: clientId
        })
        .get()
        .then(response => {
          this.clientBoards[clientId] = response
        }).catch(function (error) {
          console.log(error)
        })
    },
    getUserBoardMemberships () {
      /*
      const params = { role: 'member' }
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/user-role/', params)
        .then((response) => {
          console.log(response.data)
        }).catch(function (error) {
          console.log(error)
        })
      */
    },
    cancelRequest (clientId) {
      /*
      console.log('right call ' + clientId)
      return false
      */
      axios.put(process.env.VUE_APP_BACKEND_URL + '/api/join-request/' + clientId, { cancel: true })
        .then((response) => {
          this.getAllOrganizations()
        }).catch(function (error) {
          console.log(error)
        })
    }
  },
  beforeMount () {
    this.getAllOrganizations()
    this.getUserBoardMemberships()
  },
  updated () {
    //
  }
})
</script>
