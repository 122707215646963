<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>
          <span>User Profile</span>
        </span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="First Name"
              hide-details
              v-model="user.firstName"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              label="Last Name"
              hide-details
              v-model="user.lastName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="Email"
              v-model="user.email"
            ></v-text-field>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
        <v-btn depressed width="130" color="secondary">
          <span>Update</span>
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'UserProfile',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      user: {
        firstName: 'Charles',
        lastName: 'Simon',
        email: 'charles@agendease.com',
        passwordCurrent: {
          view: false,
          value: ''
        },
        passwordNew: {
          view: false,
          value: ''
        },
        passwordConfirmation: {
          view: false,
          value: ''
        }
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
