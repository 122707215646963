<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>
          <span>Change Password</span>
        </span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="Current Password"
              hide-details
              v-model="password.current.value"
              :type="password.current.view ? 'text' : 'password'"
              :append-icon="password.current.view ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="password.current.view = !password.current.view"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="New Password"
              hide-details
              v-model="password.new.value"
              :type="password.new.view ? 'text' : 'password'"
              :append-icon="password.new.view ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="password.new.view = !password.new.view"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="New Password confirmation"
              hide-details
              v-model="password.confirmation.value"
              :type="password.confirmation.view ? 'text' : 'password'"
              :append-icon="password.confirmation.view ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="password.confirmation.view = !password.confirmation.view"
            ></v-text-field>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
        <v-btn depressed width="130" color="secondary">
          <span>Update</span>
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ChangePassword',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      password: {
        current: {
          view: false,
          value: ''
        },
        new: {
          view: false,
          value: ''
        },
        confirmation: {
          view: false,
          value: ''
        }
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
