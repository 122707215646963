var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900","persistent":this.$store.state.modalNotCloseOutside,"no-click-animation":this.$store.state.modalWithoutAnimation},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-8 veryDarkGrey--text",attrs:{"elevation":"0","rounded":"0"}},[_c('v-card-title',{staticClass:"d-flex justify-center headline flex-column mb-6"},[_c('span',[_vm._v("Your Organizations "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"darkGrey","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" Manage all the organizations to which you belong as either a staff member, a board member, or a notification recipient ")])])],1)]),_c('span',{staticClass:"modal-close-btn"},[_c('v-btn',{attrs:{"icon":"","color":"grey"},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-tabs',{attrs:{"color":"secondary","fixed-tabs":"","icons-and-text":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tabs-slider'),_c('v-tab',{staticClass:"mr-8 d-flex justify-center",attrs:{"name":"staffTab"}},[_vm._v(" Staff "),_c('v-icon',[_vm._v("mdi-account-outline")])],1),_c('v-tab',{staticClass:"mr-8 d-flex justify-center",attrs:{"name":"boardMemberTab"}},[_vm._v(" Board Member "),_c('v-icon',[_vm._v("mdi-gavel")])],1),_c('v-tab',{staticClass:"mr-8 d-flex justify-center",attrs:{"name":"notificationsTab"}},[_vm._v(" Notifications "),_c('v-icon',[_vm._v("mdi-inbox-arrow-down")])],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"d-flex align-center justify-center"},[_vm._v("You have staff user access to the organizations listed below.")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","dark":"","large":"","color":"accentYellow","href":"/user-organizations"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-bank-plus ")])],1)]}}])},[_c('span',[_vm._v(" Request to join another organization ")])])],1)],1),_c('v-list',{staticStyle:{"background-color":"transparent"},attrs:{"light":""}},_vm._l((_vm.userOrganizations),function(organization){return _c('v-list-item',{key:organization.id,staticClass:"my-1 blue-grey lighten-5",staticStyle:{"border":"solid 2px black","border-radius":"10px"}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.pathToClientAvatars + organization.logo}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(organization.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(organization.city)+", "+_vm._s(organization.state)+" ")])],1),_c('v-list-item-action',[_c('v-speed-dial',{attrs:{"direction":"left","open-on-hover":false,"transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","fab":"","x-small":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},on),[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-back ")]):_c('v-icon',{attrs:{"color":"accentRed"}},[_vm._v("mdi-bank-off-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Leave the "+_vm._s(organization.name)+" organization account ")])])]},proxy:true}],null,true)},[_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"grey"}},[_vm._v(" no ")]),_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"green"}},[_vm._v(" yes ")])],1)],1)],1)}),1)],1),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"d-flex align-center justify-center"},[_vm._v("You have pending join requests to the organizations listed below.")])])],1),_c('v-list',{staticStyle:{"background-color":"transparent"},attrs:{"light":""}},_vm._l((_vm.userOrganizationsPending),function(organization){return _c('v-list-item',{key:organization.id,staticClass:"my-1 blue-grey lighten-5",staticStyle:{"border-radius":"10px"}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.pathToClientAvatars + organization.logo}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(organization.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(organization.city)+", "+_vm._s(organization.state)+" ")])],1),_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(organization.need_auth)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" The administrator for the "+_vm._s(organization.name)+" organization account has requested additional information from you before the request can be approved."),_c('br'),_vm._v(" Please check your email for a message regarding the request, and contact the organization if you have questions. ")])])],1),_c('v-list-item-action',[_c('v-speed-dial',{attrs:{"direction":"left","open-on-hover":false,"transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"accentRed","fab":"","x-small":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},on),[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-back ")]):_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v(" Cancel your request to join the "+_vm._s(organization.name)+" organization account ")])])]},proxy:true}],null,true)},[_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"grey"}},[_vm._v(" no ")]),_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"green"},on:{"click":function($event){return _vm.cancelRequest(organization.id)}}},[_vm._v(" yes ")])],1)],1)],1)}),1)],1)],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_vm._v(" Board Members ")])],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row mb-4"},[_c('span',{staticClass:"d-flex align-center justify-center"},[_vm._v("You have subscribed to receive notifications from at least one board the organizations listed below. Expand the organization to view the boards for which you are receiving notification.")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","dark":"","large":"","color":"accentBlue","href":"/user-notifications"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cog ")])],1)]}}])},[_c('span',[_vm._v(" Manage your notifications ")])])],1)],1),_c('v-expansion-panels',{staticStyle:{"background-color":"transparent"},attrs:{"light":""}},_vm._l((_vm.userNotifications),function(notificationClient,clientId,index){return _c('v-expansion-panel',{key:index,staticClass:"my-1 lighten-5",staticStyle:{"border":"solid 2px black","border-radius":"10px"}},[_c('v-expansion-panel-header',{staticClass:"pa-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-img',{attrs:{"contain":"","height":"40","src":_vm.pathToClientAvatars + notificationClient.logo}})],1),_c('v-col',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(notificationClient.name))]),_c('br'),_vm._v(" "+_vm._s(notificationClient.city)+", "+_vm._s(notificationClient.state)+" ")]),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',[_c('v-list',{staticStyle:{"background-color":"transparent"},attrs:{"light":""}},_vm._l((notificationClient.boards),function(notificationBoard,boardId,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(notificationBoard.name)+" ")])],1)],1)}),1)],1)],1)}),1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex align-center justify-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","width":"130"},on:{"click":_vm.closeModal}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }