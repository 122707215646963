<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="true"
      width=300
      app
      color="primary"
      dark
    >

      <v-row class="d-flex mt-6">
        <v-col class="d-flex justify-center pl-8 pr-8">
          <v-avatar
            color="transparent"
            size="40"
            @click="logoSample = !logoSample"
            tile
            class="rounded-sm"
          >
            <v-icon color="secondary" v-if="!logoSample">mdi-city-variant-outline</v-icon>
            <v-img
              v-if="logoSample"
              :src="require(`@/assets/${this.activeOrganization.logo}`)"
            >
              <template v-slot:placeholder>
                <v-icon color="secondary">mdi-city-variant-outline</v-icon>
              </template>
            </v-img>
          </v-avatar>
          <span class="secondary--text text-uppercase font-weight-bold text-caption ml-4 mr-4">
            {{ this.activeOrganization.name }}
          </span>
          <span>
            <v-menu
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                icon
                v-bind="attrs"
                v-on="on"
                large
              >
                <v-icon
                  size="20"
                >
                  mdi-swap-horizontal
                </v-icon>
              </v-btn>
            </template>
            <v-list>

              <span v-for="(item, i) in organizations" :key="i">
                <v-list-item
                  @click="switchOrganization(item)"
                >
                  <v-list-item-icon class="my-2">
                    <v-avatar
                      size="60"
                      tile
                      class="rounded-sm"
                    >
                      <v-icon v-if="item.logo===''">mdi-city-variant-outline</v-icon>
                      <v-img
                        v-if="item.logo!==''"
                        :src="require(`@/assets/${item.logo}`)"
                      >
                        <template v-slot:placeholder>
                          <v-icon>mdi-city-variant-outline</v-icon>
                        </template>
                      </v-img>
                    </v-avatar>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <v-row>
                      <v-col class="d-flex align-center">
                        {{ item.name }}
                      </v-col>
                      <v-col class="d-flex align-center justify-end">

                      <v-tooltip bottom color="secondary" v-if="item.roleStaff">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="secondary">mdi-account-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Staff
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom color="secondary" v-if="item.roleBoard">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="secondary">mdi-gavel</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Board Member
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom color="secondary" v-if="item.roleNotification">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="secondary">mdi-inbox-arrow-down</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Notification Recipient
                        </span>
                      </v-tooltip>

                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item>
              </span>

            </v-list>
          </v-menu>
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex justify-center">

          <v-tooltip bottom color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="secondary">mdi-account-outline</v-icon>
              </v-btn>
            </template>
            <span>
              Staff
            </span>
          </v-tooltip>

          <v-tooltip bottom color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="secondary" class="opacity-extra-high">mdi-gavel</v-icon>
              </v-btn>
            </template>
            <span>
              Board Member
            </span>
          </v-tooltip>

          <v-tooltip bottom color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="secondary" class="opacity-extra-high">mdi-inbox-arrow-down</v-icon>
              </v-btn>
            </template>
            <span>
              Notification Recipient
            </span>
          </v-tooltip>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0">

          <v-list>
            <template v-for="item in items">

              <v-row
                v-if="item.heading"
                :key="item.heading"
                align="center"
              >
              </v-row>

              <v-list-group
                v-else-if="item.children"
                :key="item.text"
                v-model="item.model"
                :prepend-icon="item.icon"
              >

                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(child, i) in item.children"
                  :key="i"
                  link
                  @click="$router.push({ name: child.name }).catch(()=>{})"
                >

                  <v-list-item-action>
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ child.text }}
                    </v-list-item-title>
                  </v-list-item-content>

                </v-list-item>

              </v-list-group>

              <v-list-item
                v-else
                :key="item.text"
                link
                @click="$router.push({ name: item.name }).catch(()=>{})"
                :class="item.highlight? 'secondary':''"
              >
                <v-list-item-action>
                  <v-icon
                    :color="item.highlight? 'white' : 'secondary'"
                  >
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="d-flex align-center justify-space-between">
                    {{ item.text }}
                    <span v-if="item.count" class="dashboard-badge-animation">
                      <v-icon color="orange lighten-3">
                        mdi-alert-decagram
                      </v-icon>
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </template>

          </v-list>

        </v-col>
      </v-row>

    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="true"
      app
      color="white"
      elevation="1"
    >

      <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="darkGrey"></v-app-bar-nav-icon>

      <span
        class="ml-0 pl-2"
      >
        <v-img
          contain
          width="120"
          height="40"
          src="@/assets/agendease-logo-sample.jpg"
        ></v-img>
      </span>

      <v-spacer></v-spacer>

      <v-text-field
        @focus="searchClose = false"
        @blur="searchClose = true"
        prepend-inner-icon="mdi-magnify"
        :label="!searchClose ? 'Search' : ''"
        single-line
        hide-details
        class="searchAgendease mx-1 d-flex"
        :class="{ 'closed': searchClose && !search }"
        filled
        dense
        v-model="search"
        clearable
      ></v-text-field>

      <v-btn
        icon color="secondary"
        title="Support"
        @click="$router.push('Support').catch(()=>{})"
        class="mx-1 d-flex"
      >
        <v-icon>mdi-lifebuoy</v-icon>
      </v-btn>

      <v-menu
        offset-y
        class="mx-1 d-flex"
        max-width="450"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            bordered
            color="secondary"
            :content="messages < 100 ? messages : '99+'"
            :value="messages"
            overlap
            class="custom-badge"
          >
            <v-btn
              icon color="darkGrey"
              title="Messages"
              class="opacity-high"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-message-outline</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <v-list
        >
          <v-badge
            :content="messages"
            :value="messages"
            inline
            overlap
            left
            color="secondary"
            class="mx-3 d-flex justify-space-between"
          >
            <v-subheader
              class="darkGrey--text text-uppercase font-weight-bold"
            >
              Messages
            </v-subheader>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="secondary"
              class="font-weight-medium"
              small
            >
              View all
            </v-btn>
          </v-badge>
          <v-list-item
            @click="viewApproval"
            v-for="(item, i) in messagesSample" :key="i"
          >
            <v-list-item-content class="text-caption">
              {{ item }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        offset-y
        class="mx-1 d-flex"
        max-width="450"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            bordered
            color="secondary"
            :content="approvals < 100 ? approvals : '99+'"
            :value="approvals"
            overlap
            class="custom-badge"
          >
            <v-btn
              icon color="darkGrey"
              title="Approvals"
              class="opacity-high"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-check-circle-outline</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <v-list>
          <v-badge
            content="315"
            inline
            overlap
            color="secondary"
            left
            class="mx-3 d-flex justify-space-between"
          >
            <v-subheader
              class="darkGrey--text text-uppercase font-weight-bold"
            >
              Items waiting for your action
            </v-subheader>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="secondary"
              class="font-weight-regular"
              small
            >
              View all
            </v-btn>
          </v-badge>
          <v-list-item
            @click="viewApproval"
            v-for="(item, i) in approvalsAction" :key="i"
          >
            <v-list-item-content class="text-caption">
              {{ item.substring(0,characterCount)+'...' }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list
        >
          <v-badge
            content="28"
            inline
            overlap
            left
            color="secondary"
            class="mx-3 d-flex justify-space-between"
          >
            <v-subheader
              class="darkGrey--text text-uppercase font-weight-bold"
            >
              Items you may need to submit for approval
            </v-subheader>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="secondary"
              class="font-weight-regular"
              small
            >
              View all
            </v-btn>
          </v-badge>
          <v-list-item
            @click="viewApproval"
            v-for="(item, i) in approvalsSubmit" :key="i"
          >
            <v-list-item-content class="text-caption">
              {{ item.substring(0,characterCount)+"..." }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        offset-y
        class="d-flex ml-8"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
            class="py-7 px-3"
          >
            <v-avatar size="36" color="secondary" class="white--text mr-2">CS</v-avatar>
            <span class="darkGrey--text text-uppercase">{{ user.first_name }}</span>
            <v-icon color="darkGrey" size="18" class="ml-4">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="openModal('profile')">
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Profile
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="openModal('password')">
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-key</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Change password
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="openModal('organizations')">
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              My Organizations
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="openModal('settings')">
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Settings
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="openModal('logout')">
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>

    <v-main>
      <v-container class="fill-height">
        <loader v-if="loading" />
        <slot v-else></slot>
      </v-container>
    </v-main>

    <account-settings v-model="modals.settings"></account-settings>
    <user-profile v-model="modals.profile"></user-profile>
    <change-password v-model="modals.password"></change-password>
    <my-organizations v-model="modals.organizations"></my-organizations>
    <logout v-model="modals.logout"></logout>

  </div>

</template>

<script>
import Vue from 'vue'
import '../assets/css/style.scss'
import { mapGetters } from 'vuex'
import AccountSettings from '@/components/AccountSettings'
import UserProfile from '@/components/UserProfile'
import ChangePassword from '@/components/ChangePassword'
import MyOrganizations from '@/components/MyOrganizations'
import Logout from '@/components/Logout'

export default Vue.extend({
  name: 'Portal',
  components: {
    AccountSettings,
    ChangePassword,
    UserProfile,
    MyOrganizations,
    Logout
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    inUserOrganization () {
      return this.$route.name === 'UserOrganizations'
    }
  },
  data: () => ({
    logoSample: true,
    searchClose: true,
    search: null,
    modals: {
      logout: false,
      password: false,
      profile: false,
      settings: false,
      organizations: false
    },
    messages: 8,
    alerts: 1,
    approvals: 343,
    characterCount: 180,
    drawer: null,
    loading: false,
    organizations: '',
    activeOrganization: {
      name: 'loading',
      logo: 'agendease.png'
    },
    items: [
      { icon: 'mdi-apps', text: 'Dashboard', name: 'Dashboard', count: 3 },
      { icon: 'mdi-account-group', text: 'Meetings', name: 'Meetings', highlight: true },
      { icon: 'mdi-dns', text: 'Items', name: 'Items', highlight: true },
      { icon: 'mdi-folder-outline', text: 'Documents', name: 'Documents' },
      {
        icon: 'mdi-view-compact',
        text: 'Templates',
        model: false,
        children: [
          { icon: '', text: 'Agenda Styles', name: 'AgendaStyles' },
          { icon: '', text: 'Item Types', name: 'ItemTypes' },
          { icon: '', text: 'Vote Formats', name: 'VoteFormat' },
          { icon: '', text: 'Recurring Items', name: 'RecurringItems' }
        ]
      },
      {
        icon: 'mdi-cogs',
        text: 'Admin Panel',
        model: false,
        children: [
          { text: 'Users', name: 'Users' },
          { text: 'Boards', name: 'Boards' },
          { text: 'Workgroups', name: 'Workgroups' },
          { text: 'Approval Chains', name: 'ApprovalChains' },
          { text: 'Auto-Notifications', name: 'AutoNotifications' },
          { text: 'Integrations/Social Media', name: 'Integrations' },
          { text: 'General Settings', name: 'GeneralSettings' },
          { text: 'Code Snippets', name: 'CodeSnippets' }
        ]
      }
    ],
    approvalsAction: [
      'Conduct a public hearing to receive public comment and testimony regarding zoning changes initiated by the Planning and Zoning Commission, changing the current zoning from A/O: Agriculture/open Space District to R-1C: low density, single dwelling unit, 2 acre lot or larger, residential district for the properties legally described as, A0002. D ARNOLD, TRACT 17, PAR 9-4, ACRES 3.064, 17, PAR 9-4, A0002. D ARNOLD, TRACT 18, ACRES 159.5, PAR 3-2, A0002. D ARNOLD, TRACT 18, PAR 8, ACRES 2.15, A0002. D ARNOLD, TRACT 18, PAR 2, ACRES 28.23 and A0002. D ARNOLD, TRACT 18, PAR 1, ACRES 7.8, changing the current zoning from A/O: Agriculture/open Space District to R-3: high density, multi-dwelling unit, residential district for the properties legally described as A0002. D ARNOLD, TRACT 18, PAR 9, ACRES 3, A0002. D ARNOLD, TRACT 18, PAR 4, ACRES 1.5 and A0002. D ARNOLD, TRACT 18, PAR 6, ACRES 2.16, changing the current zoning from A/O: Agriculture/open Space District to R-1B: medium density, single dwelling unit, quarter acre, residential district for the property legally described as A0002. D ARNOLD, TRACT 18, PAR 1-5, ACRES 1.00, located in Navasota, Grimes County, Texas.',
      'Staff Report: (a) Staff recognition for years of service; (b) Board and Commission update; and (c) Reports from City Staff or City Officials regarding items of community interests, including expressions of thanks, congratulations or condolence; information regarding holiday schedules; honorary or salutary recognition of public officials, public employees, or other citizens; reminders about upcoming events organized or sponsored by the City; information regarding social, ceremonial, or community events organized or sponsored by a non-City entity that is scheduled to be attended by City officials or employees; and announcements involving imminent threats to the public health and safety of people in the City that has arisen after the posting of the agenda.',
      'The City Council will convene in closed session as authorized by Texas Government Code Section 551.071(1)(A) - Pending or Contemplated Litigation: consultation with city attorney to discuss enforcement of the extraterritorial jurisdiction (ETJ) exchange agreement between the City of La Porte and the City of Pasadena. [Clark T. Askins, Assistant City Attorney]'
    ],
    approvalsSubmit: [
      'Resolution No. R-20-___ Authorizing the Execution of an Agreement with TxDOT for the Temporary Closure of State Right of Way in Connection with the 2020 Christmas Stroll and Lighted Parade to be held on ____(Crystal).',
      'The City Council will convene in closed session as authorized by Texas Government Code Section 551.071(1)(A) - Pending or Contemplated Litigation: consultation with city attorney to discuss enforcement of the extraterritorial jurisdiction (ETJ) exchange agreement between the City of La Porte and the City of Pasadena. [Clark T. Askins, Assistant City Attorney]',
      'Conduct a public hearing for the purpose of receiving public comments and testimony regarding a variance request submitted by David M. Dubbelde, for the property located at 112 North Jones Street, Navasota, Grimes County, Texas. The owner seeks a variance from the R-1B: medium density, single dwelling unit, quarter acre, residential district, five (5’) foot rear setback requirement for accessory units. If granted it will allow for the construction of an accessory building one (1’) foot, six (6”) inches setback from the rear property line. The property is legally described as McNair, block 3, lots 9,10 (s/2 both), & 1/2 alley.'
    ],
    messagesSample: [
      'Phasellus eu sapien est. Nulla in sapien lacinia, faucibus nulla sed, elementum diam',
      'Aliquam egestas arcu at lectus iaculis placerat. Vestibulum semper metus ac lorem commodo iaculis. Praesent a hendrerit lectus, vel mattis enim',
      'Duis sodales ex urna, et blandit arcu porta ac. Nulla non tincidunt odio',
      'Maecenas consectetur pharetra diam, euismod sollicitudin turpis ultrices ornare. Nunc odio sem, euismod ac ultricies sit amet, feugiat vel dui. Curabitur bibendum, mauris a mattis finibus, libero diam lacinia nunc',
      'Nam pulvinar urna enim, non aliquam justo vestibulum et. Quisque vel auctor lacus, sed feugiat magna. In pretium et eros sit amet lacinia'
    ],
    alertsSample: [
      'Check up new features'
    ]
  }),
  created () {
    this.organizations = this.$store.state.client.availableClientList
    if (this.$store.state.client.activeClient !== null) {
      this.activeOrganization = this.$store.state.client.activeClient
    }
  },
  methods: {
    openModal (item, optional) {
      if (optional) this.modeType = optional
      this.modals[item] = true
    },
    switchOrganization (item) {
      this.$store.commit('client/setActiveClient', item)
      this.activeOrganization = item
    },
    viewApproval () {
      console.log('view approval item')
    }
  }
})
</script>
